<template>
  <div class="box">
    <div
      class="turntable-box"
      v-show="loading"
      v-bg="{
        remoteSrc: imgList.backgroundImg,
        localSrc: require('@/assets/home-bg.png')
      }"
    >
      <!-- 转盘 -->
      <div class="outer">
        <!-- 转盘外圈图 -->
        <img
          v-bg="{
            remoteSrc: imgList.prizePlateConfig.outerImg,
            localSrc: require('@/assets/turntable-outer.png')
          }"
          alt=""
        />
        <div class="inner" id="turnUl">
          <!-- 转盘内圈图 -->
          <img
            v-bg="{
              remoteSrc: imgList.prizePlateConfig.innnerImg,
              localSrc: require('@/assets/turntable-inner.png')
            }"
            alt=""
          />
          <ul
            class="prize"
            :style="{ webkitTransform: 'rotate(' + -base + 'deg)' }"
          >
            <li
              v-for="(item, index) in prizeList"
              :key="index"
              :style="{ webkitTransform: 'rotate(' + -item.angle + 'deg)' }"
            >
              <img :src="item.prizeImg" alt="" />
            </li>
          </ul>
        </div>
        <!-- 转盘指针 -->
        <div
          class="pointer"
          @click="startrotate()"
          v-tracking="{ userVisit: '3' }"
        >
          <img
            v-bg="{
              remoteSrc: imgList.pointImg,
              localSrc: require('@/assets/turntable-pointer.png')
            }"
            alt=""
          />
        </div>
      </div>

      <div class="question">
        <!-- 问题咨询 -->
        <p class="item" @click="questionHref()">
          <img src="../../assets/wtzx.png" alt="" />
        </p>
        <!-- 隐私协议 -->
        <p class="item" @click="onProtocol()">
          <img src="../../assets/yssm.png" alt="" />
        </p>
      </div>
      <!-- 中奖名单 -->
      <div class="winning">
        <img
          v-bg="{
            remoteSrc: imgList.prizeFormImg,
            localSrc: require('@/assets/winners.png')
          }"
          alt=""
        />
        <!-- <img src="@/assets/中奖名单.png" alt="" /> -->
        <div class="winningBox">
          <vue-seamless-scroll
            class="seamless-warp"
            :data="winningList"
            :class-option="classOption"
          >
            <ul class="winningList">
              <li v-for="(item, index) in winningList" :key="index">
                <span>{{ item.prizeName }}</span>
                <span>{{ item.createTime }}</span>
              </li>
            </ul>
          </vue-seamless-scroll>
        </div>
      </div>
      <!-- 广告 -->
      <ad></ad>
      <!-- 活动规则 -->
      <div class="rule">
        <div class="rule-box">
          <baseActivityRules />
        </div>
      </div>
      <div class="support">
        <img src="../../assets/jszc.png" alt="" />
      </div>
    </div>
    <!-- 中奖记录 -->
    <assistant @click="showFather" v-tracking="{ userVisit: '8' }"></assistant>
    <winningRecord
      :recordShow="recordShow"
      :recordList="recordList"
      @click="showFather"
    ></winningRecord>
  </div>
</template>

<script>
import {
  getImgUrl,
  getDrawRecord,
  drawRecord,
  getWinningRecord
} from '@/api/index.js'
import preventBack from '@/mixins/prevent-browser-back'
import assistant from '@/components/assistant/index'
import winningRecord from '@/components/winningRecord/index'
import { areaList } from '@vant/area-data'
import ad from '@/components/ad/index'

export default {
  name: 'turntable',
  mixins: [preventBack],
  data() {
    return {
      recordShow: false, //展示小助手
      openState: true,
      loading: true,
      winningList: [],
      prizeList: [],
      recordList: {
        prizeLogLists: [],
        wealLogLists: [],
        awardsLogLists: []
      },
      time: 3000, // 旋转时间
      rotNum: 3, // 旋转圈数基数
      imgList: {
        pointImg: '',
        prizeFormImg: '',
        rulesImg: '',
        backgroundImg: '',
        prizePlateConfig: {
          outerImg: '',
          innnerImg: ''
        }
      },
      base: '',
      tipsShow: false,
      shopInputShow: false,
      isBinding: false,
      showArea: false,
      shop: {
        shopName: '',
        shopPhone: '',
        shopDetailed: '',
        shopAddress: '',
        province: '',
        city: '',
        area: ''
      },
      areaList: areaList
    }
  },
  components: {
    assistant: assistant,
    winningRecord: winningRecord,
    ad
  },
  computed: {
    classOption() {
      return {
        step: 0.5, // 数值越大速度滚动越快
        limitMoveNum: this.winningList.length, // 开始无缝滚动的数据量 this.dataList.length
        hoverStop: false, // 是否开启鼠标悬停stop
        direction: 1, // 0向下 1向上 2向左 3向右
        // openWatch: true, // 开启数据实时监控刷新dom
        singleHeight: 0, // 单步运动停止的高度(默认值0是无缝不停止的滚动) direction => 0/1
        singleWidth: 0, // 单步运动停止的宽度(默认值0是无缝不停止的滚动) direction => 2/3
        waitTime: 1000, // 单步运动停止的时间(默认值1000ms)
        base: '22.5',
        rotate: 0,
        openTouch: false
      }
    }
  },
  methods: {
    autoRotate(arr) {
      if (arr.length) {
        let len = arr.length
        let base = 360 / len
        this.base = 360 / len
        arr.forEach((item, index) => {
          // 指的是某个奖品区域的中间 : base/2
          item.angle = 360 - (base / 2 + index * base)
          this.$set(arr, index, item)
        })
      }
      return arr
    },
    //开始旋转 angle角度  complete回调成功函数
    startrotate() {
      console.log(this.openState)
      var that = this

      if (this.openState) {
        this.openState = false
        drawRecord().then(
          res => {
            this.oTurntable.style.webkitTransition =
              'transform ' +
              this.time / 1000 +
              's cubic-bezier(0.01,0.5,0.01,1)'
            var angle = 360 - (res.data.sequenceId * this.base - this.base / 2)
            // var angle = (res.data.sequenceId - 1) * this.base - this.base / 2;
            this.rotate = 2160 * (this.rotNum + 1) + Number(angle)
            this.oTurntable.style.webkitTransform =
              'rotate(' + this.rotate + 'deg)'
            clearTimeout(this.timer)
            // 1、实物 0、电子 3、红包
            // 设置5秒后停止旋转,处理接口返回的数据
            this.timer = setTimeout(() => {
              this.openState = true
              if (res.data.isTrue == 1 || res.data.isTrue == 0) {
                window.location.href = res.data.skipUrl
              } else if (res.data.isTrue == 3) {
                this.$router.push({
                  path: '/redEnvelopes',
                  query: {
                    prizeId: res.data.prizeId,
                    drawLogId: res.data.drawLogId
                  }
                })
              }
            }, this.time + 1000)
          },
          function () {
            that.openState = true
          }
        )
      }
    },
    showFather() {
      this.recordShow = !this.recordShow
      if (this.recordShow) {
        document.body.style.overflow = 'hidden'
        getWinningRecord().then(res => {
          this.recordList = res.data
        })
      } else {
        document.body.style.overflow = '' //出现滚动条
      }
    },
    getImg() {
      getImgUrl({}).then(({ data }) => {
        this.prizeList = data.prizePlateConfig.prizePlatConfig
        this.imgList = data
        this.loading = true
        this.autoRotate(data.prizePlateConfig.prizePlatConfig)
      })
    },
    getWinning() {
      getDrawRecord().then(({ data }) => {
        this.winningList = data
        this.oTurntable = document.querySelector('#turnUl')
        // 过度中属性用时5s
        this.oTurntable.style.webkitTransition =
          'transform ' + this.time / 1000 + 's cubic-bezier(0.01,0.5,0.01,1)'
      })
    },
    questionHref() {
      var openId = sessionStorage.getItem('openId')
      var projectCode = sessionStorage.getItem('projectCode')
      window.location.href = `${process.env.VUE_APP_QUESTION_URL}/questionPhase/questionPhase.html?channelId=${projectCode}&titleName=${process.env.VUE_APP_QUESTION_TITLE}&openId=${openId}`
    },
    goBack() {
      this.rotate = 0
      this.oTurntable.style.webkitTransition =
        'transform 0s cubic-bezier(0.01,0.5,0.01,1)'
      this.oTurntable.style.webkitTransform = 'rotate(0deg)'
      console.log('返回')
    },
    onProtocol() {
      // this.$router.push({
      //   name: 'PrivacyAdult'
      // })

    console.log("马牙箱-跳转外链env配置")
    window.location.href = process.env.VUE_APP_PRIVACYSTATEMENT_URL
    }
  },
  mounted() {
    console.log('更新啦1')
    this.$nextTick(() => {
      setTimeout(() => {
        this.getWinning() //获取数据接口方法
      }, 500)
    })

    var that = this
    var browserRule = /^.*((iPhone)|(iPad)|(Safari))+.*$/
    if (browserRule.test(navigator.userAgent)) {
      window.onpageshow = function (event) {
        if (event.persisted) {
          that.goBack()
        }
      }
    }
    this.$nextRequest(this.getImg)
    this.$nextRequest(this.getWinning)
    this.$nextRequest(this.shopInfo)
  }
}
</script>

<style lang="less" scoped>
.turntable-box {
  width: 100%;
  background-size: 100%;
  background-repeat: no-repeat;
  background-position: top;
  padding-top: 398px;
}

.outer {
  position: relative;
  width: 666px;
  margin: 0 auto;
  overflow: hidden;
}

.inner {
  width: 610px;
  position: absolute;
  top: 30px;
  left: 30px;
}

.pointer {
  width: 188px;
  position: absolute;
  left: 50%;
  top: 200px;
  transform: translateX(-50%);
  z-index: 2;
}

.question {
  width: 140px;
  margin: 35px auto 50px;
  .item {
    margin-bottom: 24px;
  }
  .item:last-child {
    margin-bottom: 0;
  }
}

.winning {
  position: relative;
  width: 500px;
  margin: 0 auto;
}

.winningBox {
  position: absolute;
  width: 360px;
  height: 140px;
  left: 50%;
  transform: translateX(-50%);
  top: 100px;
  overflow: hidden;
}

.winningList {
  li {
    display: flex;
    justify-content: space-between;
    font-size: 24px;
    line-height: 44px;
    span {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      display: block;
      &:nth-child(2) {
        width: 180px;
      }
      &:last-child {
        text-align: right;
      }
      &:last-child {
        width: 120px;
        flex-shrink: 0;
      }
      &:first-child {
        flex-shrink: 1;
      }
    }
  }
}

.rule {
  position: relative;
  width: 650px;
  margin: 40px auto;
  border-radius: 10px;
  background: #fff;
  border: 6px solid rgba(241, 39, 56, 0.82);
  height: 852px;
  .rule-box {
    position: absolute;
    top: 42px;
    left: 40px;
    right: 40px;
    bottom: 46px;
    overflow-y: auto;
    overflow-x: hidden;
    padding: 0 6px;
  }
}
.support {
  width: 100%;
  background: #ececec;
}

.prize {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  li {
    position: absolute;
    box-sizing: border-box;
    color: #7e250d;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    line-height: 20px;
    transform-origin: 50% 50%;
    img {
      position: absolute;
      top: 20px;
      left: 50%;
      transform: translateX(-50%);
      width: 148px;
    }
  }
}
</style>
