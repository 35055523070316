<template>
  <van-swipe
    class="ad-swipe ad-swipe-1"
    :autoplay="3000"
    @change="changeSwipe"
    v-if="list.length > 0"
  >
    <van-swipe-item v-for="(item, index) in list" :key="index">
      <img width="100%" height="100%" :src="item.pictureUrl" />
      <div v-if="item.skipType == 1" @click="onLink(item)" class="skip"></div>
      <wx-open-launch-weapp
        :username="item.originalId"
        :path="item.skipLink"
        class="skip"
        v-if="item.skipType == 2"
        @click="sendAdLog(item, 2)"
      >
        <script type="text/wxtag-template">
          <style>.weapp-btn {width: 100vw; height: 100vw;}</style>
          <div class="weapp-btn">&nbsp;</div>
        </script>
      </wx-open-launch-weapp>
    </van-swipe-item>
  </van-swipe>
</template>
<script>
import { adList } from '@/api/ad'
import { Swipe, SwipeItem } from 'vant'
import { sendTrack } from '@/utils/sendTrack'

export default {
  components: {
    [Swipe.name]: Swipe,
    [SwipeItem.name]: SwipeItem
  },
  data() {
    return {
      list: []
    }
  },
  methods: {
    getAdList() {
      adList().then(({ data }) => {
        // 已发布状态
        if (Array.isArray(data)) {
          this.list = data.filter(item => item.status == 1)
          this.sendAdLog(this.list[0], 1)
        }
      })
    },
    onLink(item) {
      this.sendAdLog(item, 2)
      window.location.href = item.skipLink
    },
    changeSwipe(index) {
      this.sendAdLog(this.list[index], 1)
    },
    sendAdLog(item, operate) {
      sendTrack(process.env.VUE_APP_BASE_API + '/advertise/saveAdvertisesLog', {
        location: 2, // 轮播图
        advertiseTitle: item.advertiseTitle, // 广告标题
        advertiseType: item.advertiseType, //广告类型 1：图片 2：视频
        pictureUrl: item.pictureUrl, //图片地址
        videoUrl: item.videoUrl || '', //视频地址
        linkType: item.skipType, // 1:h5; 2：小程序
        originalId: item.originalId, //"小程序原始id"
        linkUrl: item.skipLink, //跳转链接
        operate // 操作：1：曝光 2：点击
      })
    }
  },
  created() {
    this.getAdList()
  }
}
</script>
<style lang="less" scoped>
.ad-swipe {
  margin: 0 auto;
  width: 640px;
  height: 272px;
  border-radius: 20px;
  overflow: hidden;
  transform: translate3d(0, 0, 0);
  .van-swipe-item {
    overflow: hidden;
  }

  ::v-deep {
    .van-swipe__indicator {
      margin: 0 4px;
      opacity: 1;
      color: #fff;
    }
    .van-swipe__indicator.van-swipe__indicator--active {
      background: #feb933 !important;
    }
  }
  .skip {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 99;
  }
}
.ad-swipe-1 {
  margin-top: 40px;
  overflow: hidden;
}
</style>
